import React from "react"
import Seo from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const PrivacyPage = () => {
  return (
    <>
      <Seo />
      <Layout>
        <Banner content="This is the privacy page, no snooping is allowed here" />
      </Layout>
    </>
  )
}

export default PrivacyPage
